<template>
    <div>
      <h1>Sample views of Unified APP</h1>
      <language-selector></language-selector>
      <h2>Sample text in selected language:</h2> {{ $t('username') }}
      <div>
        <Button :label="'Go to Unified Patient Page'" @click="goToUP"/>
      </div>
      <h2>Input Component</h2>
      <Input
        name="sampleInput"
        placeholderText="Placeholder"
        v-model="inputText"
        orientation='v'
        label='Test Label'
      />
      <Input
        name="sampleDisabledInput"
        placeholderText="Placeholder"
        isDisabled
        orientation='h'
        label='Test Label'
      />
      <Input
        name="sampleErrorInput"
        placeholderText="Placeholder"
        isRequired
        orientation='v'
      />
      <Input
        name="sampleErrorInput"
        placeholderText="Placeholder"
        :minLength="minLength"
        orientation='v'
      />
      <Input
        name="sampleAddOnInput"
        addOn="+91"
        placeholderText="Placeholder"
        :minLength="minLength"
        orientation='v'
      />

      <h2>RadioGroup Component</h2>
      <RadioGroup
        v-model="radioValue"
        :allOptions="[
          { Value: 1, Label: 'English' },
          { Value: 2, Label: 'Hindi' },
        ]"
        name="Language"
        orientation='v'
      />
      <RadioGroup
        :allOptions="[
          { Value: 3, Label: 'Amharic' },
          { Value: 4, Label: 'Luganda' },
        ]"
        isDisabled
        name="secondLanguage"
        orientation='h'
      />

      <h2>Checkbox Component</h2>
      <Checkbox
        :optionsWithKeyValue="[
          { Key: 1, Value: 'English' },
          { Key: 2, Value: 'Hindi' },
        ]"
        name="Language"
        label="Select languages:"
        v-model="checkboxValue"
        orientation='v'
      />
      <Checkbox
        :optionsWithKeyValue="[
          { Key: 3, Value: 'Japanese' },
          { Key: 4, Value: 'Portuguese' },
        ]"
        v-model="disabledCheckboxValue"
        name="Language2"
        label="Select other languages:"
        orientation='h'
      />

      <h2>Select Component</h2>
      <div>
        <Select
          name="singleSelect"
          label="Label"
          v-model="selectValue"
          :allOptions="[
            { Key: '1', Value: 'Option 1' },
            { Key: '2', Value: 'Option 2' },
            { Key: '3', Value: 'Option 3' },
            { Key: '4', Value: 'Option 4' },
            { Key: '5', Value: 'Option 5' },
            { Key: '6', Value: 'Option 6' },
            { Key: '7', Value: 'Option 7' },
            { Key: '8', Value: 'Option 8' }
          ]"
        />
      </div>

      <div>
        <Select
          name="singleSelect1"
          label="Single Select"
          :allOptions="[
            { Key: '1', Value: 'Option 1' },
            { Key: '2', Value: 'Option 2' },
            { Key: '3', Value: 'Option 3' },
            { Key: '4', Value: 'Option 4' },
            { Key: '5', Value: 'Option 5' },
            { Key: '6', Value: 'Option 6' },
            { Key: '7', Value: 'Option 7' },
            { Key: '8', Value: 'Option 8' }
          ]"
        />
      </div>

      <div>
        <Select
          name="singleSelect2"
          label="Label"
          :allOptions="[
            { Key: '1', Value: 'Option 1' },
            { Key: '2', Value: 'Option 2' },
            { Key: '3', Value: 'Option 3' },
            { Key: '4', Value: 'Option 4' },
            { Key: '5', Value: 'Option 5' },
            { Key: '6', Value: 'Option 6' },
            { Key: '7', Value: 'Option 7' },
            { Key: '8', Value: 'Option 8' }
          ]"
          :has-error="true"
          error-message="Error Message"
        />
      </div>

      <div style="margin-bottom: 30px">
        <Select
          name="multiSelect"
          label="Select options from Remote call"
          :is-multi-select="true"
          :allOptions="[]"
          remoteUrl="/UnifiedHome/SampleRemoteCall"
        />
      </div>

      <Select
        name="multiSelect"
        label="Sample label"
        :is-multi-select="true"
        v-model="multiSelectValue"
        :allOptions="[
          { Key: '1', Value: 'Option 1' },
          { Key: '2', Value: 'Option 2' },
          { Key: '3', Value: 'Option 3' },
          { Key: '4', Value: 'Option 4' },
          { Key: '5', Value: 'Option 5' },
          { Key: '6', Value: 'Option 6' },
          { Key: '7', Value: 'Option 7' },
          { Key: '8', Value: 'Option 8' },
        ]"
      />

      <div>
        <Select
          name="multiSelect"
          label="Label"
          :is-multi-select="true"
          :allOptions="[
            { Key: '1', Value: 'Option 1' },
            { Key: '2', Value: 'Option 2' },
            { Key: '3', Value: 'Option 3' },
            { Key: '4', Value: 'Option 4' },
            { Key: '5', Value: 'Option 5' },
            { Key: '6', Value: 'Option 6' },
            { Key: '7', Value: 'Option 7' },
            { Key: '8', Value: 'Option 8' },
            { Key: '9', Value: 'Option 4' },
            { Key: '10', Value: 'Option 5' },
            { Key: '11', Value: 'Option 6' },
            { Key: '12', Value: 'Option 7' },
            { Key: '13', Value: 'Option 8' },
          ]"
        />
      </div>

      <h2>Datepicker Component</h2>
      <DatePicker
        name="datepicker1"
        placeholder="Select date"
        label="Label"
        :to="DateTo"
        :from="DateFrom"
        :hasError="true"
        errorMessage="Error Text Testing message"
        :hasInfo="true"
        infoMessage="Info Text Testing message"
        orientation='v'
      />

      <DatePicker
        name="datepicker2"
        placeholder="Select date"
        label="Label"
        v-model="selectedDate"
        :hasInfo="true"
        infoMessage="Info Text Testing message"
        orientation='h'
      />

      <DatePicker
        name="datepicker3"
        placeholder="Select date"
        label="Disabled date"
        :to="DateTo"
        :from="DateFrom"
        :hasError="false"
        errorMessage="Error Text Testing message"
        :hasInfo="true"
        infoMessage="Info Text Testing message"
        orientation='h'
        :isDisabled="true"
      />
      <h2>Modal Component</h2>
      <div ref="modalName">
        <button type="button" class="btn" @click="showModal1">
          Open Modal 1!
        </button>

        <Modal
          v-show="isModal1Visible"
          bodyText="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
          @close="closeModal1"
        >
        </Modal>
      </div>

      <div ref="modalName2">
        <button type="button" class="btn" @click="showModal2">
          Open Modal 2!
        </button>

        <Modal
          v-show="isModal2Visible"
          headingText="Heading goes here"
          bodyText="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
          @close="closeModal2"
        >
        </Modal>
      </div>

      <div ref="modalName3">
        <button type="button" class="btn" @click="showModal3">
          Open Modal 3!
        </button>

        <Modal
          v-show="isModal3Visible"
          headingText="Heading goes here"
          bodyText="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
          @close="closeModal3"
        >
          <div slot="body">
            <Input name="sampleInput" placeholderText="Placeholder" />
            <Input
              name="sampleErrorInput"
              placeholderText="Placeholder"
              :minLength="minLength"
            />
          </div>
        </Modal>
      </div>
      <h2>Switch / Toggle Component</h2>
      <Toggle label="Enabled On Label" name="toggle" v-model="switchValue" />
      <Toggle label="Enabled Off Label" name="toggle" v-model="switchValue2" />
      <Toggle
        label="Disabled On Label"
        name="toggle"
        v-model="switchValue3"
        disabled
      />
      <Toggle
        label="Disabled Off Label"
        name="toggle"
        v-model="switchValue4"
        disabled
      />
      <ThemeExmaple />
      <ProgressBar :progress="progressVal" :isRadial="isRadialProgress" />
      <div class="lessWidth">
        <Accordion
          v-for="t in ttl"
          :key="t"
          :titleText="`small width test ${t}`"
          :contentIsHtml="false"
          :content="'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'"
        />
      </div>
      <Accordion
        :titleText="'full width test'"
        :contentIsHtml="false"
        :content="'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'"
      />
      <Accordion
        :titleText="'contains a link test'"
        :contentIsHtml="true"
        :content="content"
      />

      <h2>Tab Component</h2>
      <Tabs :tabs="tabsWithoutIcons" />
      <Tabs :tabs="tabsWithoutIcons" colorTheme="light" />
      <Tabs :tabs="tabsWithIcons" />
      <Tabs :tabs="tabsWithIcons" colorTheme="light" />

      <h2>Buttons</h2>
      <Button label="Primary solid" />
      <Button label="Secondary solid" color="secondary" />
      <Button label="White solid" color="white" />
      <Button label="Primary outlined" type="outlined" />
      <Button label="Secondary outlined" color="secondary" type="outlined" />
      <Button label="Primary Link" type="link" />
      <Button label="Secondary Link" color="secondary" type="link" />
      <Button label="White Link" color="white" type="link" />
      <Button label="Primary Text" type="text" />
      <Button label="Secondary Text" color="secondary" type="text" />
      <Button label="White Text" color="white" type="text" />
      <Button label="Dark Text" color="dark" type="text" />
      <h3>Above buttons in disabled state</h3>
      <Button label="Primary solid" disabled />
      <Button label="Secondary solid" color="secondary" disabled />
      <Button label="White solid" color="white" disabled />
      <Button label="Primary outlined" type="outlined" disabled />
      <Button
        label="Secondary outlined"
        color="secondary"
        type="outlined"
        disabled
      />
      <Button label="Primary Link" type="link" disabled />
      <Button label="Secondary Link" color="secondary" type="link" disabled />
      <Button label="White Link" color="white" type="link" disabled />
      <Button label="Primary Text" type="text" disabled />
      <Button label="Secondary Text" color="secondary" type="text" disabled />
      <Button label="White Text" color="white" type="text" disabled />
      <Button label="Dark Text" color="dark" type="text" disabled />
      <h3>Without Icon</h3>
      <Chips label="Enabled" color="white" />
      <Chips label="Enabled" color="primary" />
      <Chips label="Enabled" color="black" />
      <Chips label="Enabled" color="grey" />
      <h3>With Icon</h3>
      <Chips label="Enabled" color="white" :showButton="true" />
      <Chips label="Enabled" color="primary" :showButton="true" />
      <Chips label="Enabled" color="black" :showButton="true" />
      <Chips label="Enabled" color="grey" :showButton="true" />
      <StepProgress
        :steps="Steps"
        :currentStep="CurrentStep"
        :isRadial="Radial"
        :Orientation="Orient"
        :totalSteps="TotalSteps"
        :primaryText="PrimaryText"
        :secondaryText="SecondaryText"
      />

      <h2>TextBox Component</h2>
      <TextArea name="sampleTextAreaInput" placeholderText="Placeholder" orientation='v'/>
      <TextArea
        name="sampleDisabledTextAreaInput"
        placeholderText="Placeholder"
        isDisabled
      />
      <TextArea
        name="sampleTextAreaErrorInput"
        placeholderText="Placeholder"
        orientation='h'
        isRequired
      />

      <h2>Selectable TreeView Component</h2>
      <TreeView :treeData="items" v-model="treeViewItemSelected" />

      <h2>UnSelectable TreeView Component</h2>
      <TreeView :treeData="items" :selectable="false" />
      <h2> HierarchySelectionField </h2>
      <HierarchySelectionField :isEditing="true" :showLabel="true" :labelKey="'Current Treatment Center'" :hierarchyConfigs="hierarchySelectionConfigs" />

      <div>
        <h2>Form Component</h2>
        <ul>
          <p>Note:</p>
          <li>Ensure that you have logged in with "democountrylogin" user</li>
          <li>1. "TB Number" field is only visible if the value of "Surname" field is exactly "Default Value"</li>
          <li>2. The value of "Owner" field depends on the value selected in "Gender" field</li>
          <li>3. The dates in "Date of Prescription" field are disabled till the date selected in "Treatment start date" field</li>
          <li>4. The dates in "Next Refill Due Date" field are disabled from the date selected in "Treatment start date" field</li>
          <li>5. "Designation" field is only visible if the value of "Gender" field is "Value 2"</li>
          <li>6. "ART Number" field is only visible if the "Refill alarm enabled" field is checked</li>
        </ul>
      </div>
      <Form
        name="DummyForm"
        :is-editing="true"
      />
      <ThemeExample/>
      <Table :columns="columns" :data="data" title="Table Example" :searchable="true"></Table>
      <PageTurner @changePageEvent="changePage" :pageSize="5" :totalItems=51 :currentPage="selectedPage" :pageTurnerSize="5"></PageTurner>
      <Button label="Click here to go to Patient Page" @click="redirectToPatientPage()" />
    </div>
</template>

<script>
import Input from '../../../shared/components/Input.vue'
import RadioGroup from '../../../shared/components/RadioGroup.vue'
import Checkbox from '../../../shared/components/CheckboxGroup'
import Select from '../../../shared/components/Select.vue'
import Modal from '../../../shared/components/Modal'
import ProgressBar from '../../../shared/components/ProgressBar.vue'
import Button from '../../../shared/components/Button.vue'
import Tabs from '../../../shared/components/Tabs.vue'
import TextArea from '../../../shared/components/TextArea.vue'
import ThemeExample from '../../../shared/components/ThemeExample.vue'
import DatePicker from '../../../shared/components/DatePicker.vue'
// import VerticalFormPart from '../../../shared/components/VerticalFormPart.vue'
import { mapState, mapActions } from 'vuex'
import Toggle from '../../../shared/components/Switch.vue'
import Accordion from '../../../shared/components/Accordion.vue'
import StepProgress from '../../../shared/components/stepProgress.vue'
import EventBus from '@/app/shared/event-bus.js'
import TreeView from '../../../shared/components/TreeView.vue'
import Chips from '../../../shared/components/Chips.vue'
import HierarchySelectionField from '../../../shared/components/HierarchySelectionField.vue'
import Form from '../../../shared/components/Form.vue'
import LanguageSelector from '../../../shared/components/LanguageSelector.vue'
import PageTurner from '../../../shared/components/PageTurner.vue'
import Table from '../../../shared/components/Table.vue'

export default {
  name: 'Demo',
  components: {
    Button,
    Input,
    Select,
    DatePicker,
    RadioGroup,
    Checkbox,
    Modal,
    ThemeExample,
    Toggle,
    ProgressBar,
    Accordion,
    Tabs,
    // CollapsableSidebar,
    TextArea,
    // VerticalFormPart,
    StepProgress,
    TreeView,
    Form,
    PageTurner,
    Chips,
    HierarchySelectionField,
    LanguageSelector,
    Table
  },
  data () {
    return {
      isModal1Visible: false,
      isModal2Visible: false,
      isModal3Visible: false,
      selectedDate: new Date(2021, 0, 1).getTime(),
      inputText: 'test',
      selectValue: { Key: '1', Value: 'Option 1' },
      multiSelectValue: [{ Key: '1', Value: 'Option 1' }, { Key: '2', Value: 'Option 2' }],
      minLength: 5,
      radioValue: 1,
      checkboxValue: [1],
      disabledCheckboxValue: [3],
      switchValue: true,
      switchValue2: false,
      switchValue3: true,
      switchValue4: false,
      progressVal: 50,
      isRadialProgress: true,
      CurrentStep: 4,
      Radial: false,
      Orient: 'h',
      TotalSteps: 5,
      selectedPage: 1,
      DateTo: new Date('2021-09-23'),
      DateFrom: new Date('2021-10-23'),
      PrimaryText: 'Primary Text',
      SecondaryText: 'Secondary Text',
      content:
        '<p>Lorem Ipsum  is simply dummy text of the printing and typesetting industry. <a href="http://www.google.com">This is a link</a></p>',
      ttl: ['first', 'second', 'third', 'fourth'],
      tabsWithIcons: [
        {
          tab_title: 'Home',
          tab_icon: 'home',
          tab_content: 'This is the home page. Your sample begins here...'
        },
        {
          tab_title: 'Vue.js',
          tab_icon: 'vue',
          tab_content:
            'Vue.js is an open-source Model–view–viewmodel JavaScript framework for building user interfaces and single-page applications. It was created by Evan You, and is maintained by him and the rest of the active core team members coming from various companies such as Netlify and Netguru. - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_icon: 'nuxt',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_icon: 'nuxt',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_icon: 'nuxt',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_icon: 'nuxt',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        }
      ],
      tabsWithoutIcons: [
        {
          tab_title: 'Home',
          tab_content: 'This is the home page. Your sample begins here...'
        },
        {
          tab_title: 'Vue.js',
          tab_content:
            'Vue.js is an open-source Model–view–viewmodel JavaScript framework for building user interfaces and single-page applications. It was created by Evan You, and is maintained by him and the rest of the active core team members coming from various companies such as Netlify and Netguru. - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        },
        {
          tab_title: 'Nuxt.js',
          tab_content:
            'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        }
      ],
      list: [],
      columns: [
        {
          name: 'Patient Id',
          key: 'id',
          sortable: true
        },
        {
          name: 'Patient Name',
          key: 'name',
          sortable: true
        },
        {
          name: 'Phone Number',
          key: 'number',
          sortable: true
        },
        {
          name: 'Treatment',
          key: 'treatment',
          sortable: true
        },
        {
          name: 'No. of doses not reported',
          key: 'dose',
          sortable: true
        }
      ],
      data: [
        {
          id: '1',
          name: 'Patient 1',
          number: '0987654321',
          treatment: '99DOTS',
          dose: '5',
          Clickable: true,
          ClickableLink: 'Dashboard/Patient/1'
        },
        {
          id: '2',
          name: 'Patient 2',
          number: '0987654321',
          treatment: '99DOTS',
          dose: '10',
          Clickable: true,
          ClickableLink: 'Dashboard/Patient/2'
        },
        {
          id: '3',
          name: 'Patient 3',
          number: '0987654321',
          treatment: 'MERM',
          dose: '0',
          Clickable: true,
          ClickableLink: 'Dashboard/Patient/3'
        },
        {
          id: '4',
          name: 'Patient 4',
          number: '0987654321',
          treatment: '99DOTSLite',
          dose: '7',
          Clickable: false,
          ClickableLink: 'Dashboard/Patient/4'
        }
      ],
      Steps: {
        1: {
          title: 'Step 1',
          url: 'http://localhost:1/'
        },
        2: {
          title: 'Step 2',
          url: 'http://localhost:2/'
        },
        3: {
          title: 'Step 3',
          url: 'http://localhost:3/'
        },
        4: {
          title: 'Step 4',
          url: 'http://localhost:4/'
        },
        5: {
          title: 'Step 5',
          url: 'http://localhost:5/'
        }
      },
      treeViewItemSelected: [6, 15, 21],
      items: [
        {
          id: 1,
          name: 'Applications :',
          disabled: true,
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' }
          ]
        },
        {
          id: 5,
          name: 'Documents :',
          children: [
            {
              id: 6,
              name: 'vuetify :',
              children: [
                {
                  id: 7,
                  name: 'src :',
                  children: [
                    { id: 8, name: 'index : ts' },
                    { id: 9, name: 'bootstrap : ts' }
                  ]
                }
              ]
            },
            {
              id: 10,
              name: 'material2 :',
              children: [
                {
                  id: 11,
                  name: 'src :',
                  children: [
                    { id: 12, name: 'v-btn : ts' },
                    { id: 13, name: 'v-card : ts' },
                    { id: 14, name: 'v-window : ts' }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 15,
          name: 'Downloads :',
          children: [
            { id: 16, name: 'October : pdf' },
            { id: 17, name: 'November : pdf' },
            { id: 18, name: 'Tutorial : html' }
          ]
        },
        {
          id: 19,
          name: 'Videos :',
          children: [
            {
              id: 20,
              name: 'Tutorials :',
              children: [
                { id: 21, name: 'Basic layouts : mp4' },
                { id: 22, name: 'Advanced techniques : mp4' },
                { id: 23, name: 'All about app : dir' }
              ]
            },
            { id: 24, name: 'Intro : mov' },
            { id: 25, name: 'Conference introduction : avi' }
          ]
        }
      ],
      fieldsArray: [
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-input-field',
          IsDisabled: true,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label1',
          IsHierarchySelector: false,
          IsRequired: false,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 1,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'label1',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 1,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: 'Placeholder Text',
          Component: 'app-input-field',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label2',
          IsHierarchySelector: false,
          IsRequired: false,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 1,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'label2',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 1,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: 'Default Value',
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-input-field',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label3',
          IsHierarchySelector: false,
          IsRequired: false,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 1,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'label3',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 1,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-input-field',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label4',
          IsHierarchySelector: false,
          IsRequired: false,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 2,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'label4',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 1,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: 'Default Value',
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-input-field',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label5',
          IsHierarchySelector: false,
          IsRequired: true,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 2,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'Label5',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 1,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-input-field',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label One',
          Name: 'Label6',
          IsHierarchySelector: false,
          IsRequired: true,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 2,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'Label6',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 1,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: 'Placeholder Text',
          Component: 'app-select',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label test test',
          Name: 'Label7',
          IsHierarchySelector: false,
          IsRequired: true,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: [
            {
              Value: '1',
              Key: '1'
            },
            {
              Value: '2',
              Key: '2'
            },
            {
              Value: '3',
              Key: '3'
            }
          ],
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 3,
          ColumnNumber: null,
          Id: 11118,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: null,
          DefaultValue: null,
          Key: 'Label7',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 3,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-input-field',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label extra long label test test test test',
          Name: 'Label9',
          IsHierarchySelector: false,
          IsRequired: true,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: [
            { Value: 'Option1', Label: 'Option1' },
            { Value: 'Option2', Label: 'Option2' }
          ],
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 6,
          ColumnNumber: null,
          Id: 11118,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: null,
          DefaultValue: null,
          Key: 'Label9',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 3,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: 'Placeholder Text',
          Component: 'app-textarea',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label8',
          IsHierarchySelector: false,
          IsRequired: false,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: null,
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              },
              {
                Type: 'OnlyAlphabetAndSpaceAllowed',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'Only alphabet and spaces are allowed',
                ErrorMessageKey: 'OnlyAlphabetAndSpaceAllowed',
                RequiredOnlyWhen: null,
                Regex: '[a-zA-Z. ]+$'
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 11,
          ColumnNumber: null,
          Id: 11114,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: 'OnlyAlphabetAndSpaceAllowed',
          DefaultValue: null,
          Key: 'label8',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 3,
          RowWidth: 2,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-radio',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label9',
          IsHierarchySelector: false,
          IsRequired: true,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: [
            { Value: 'Option1', Label: 'Option1' },
            { Value: 'Option2', Label: 'Option2' }
          ],
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 6,
          ColumnNumber: null,
          Id: 11118,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: null,
          DefaultValue: null,
          Key: 'Label9',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 3,
          RowWidth: 1,
          HasToggleButton: false
        },
        {
          Value: null,
          PartName: 'BasicDetails',
          Placeholder: null,
          Component: 'app-checkbox-group',
          IsDisabled: false,
          IsVisible: true,
          LabelKey: '_label',
          Label: 'Label',
          Name: 'Label10',
          IsHierarchySelector: false,
          IsRequired: false,
          RemoteUrl: null,
          Type: null,
          AddOn: null,
          Options: null,
          HierarchyOptions: null,
          OptionsWithKeyValue: null,
          AdditionalInfo: null,
          DefaultVisibilty: false,
          Order: 0,
          OptionsWithLabel: [
            { Value: 'Option3', Label: 'Option3' },
            { Value: 'Option4', Label: 'Option4' }
          ],
          Validations: {
            Or: null,
            And: [
              {
                Type: 'Required',
                Max: 0,
                Min: 0,
                IsBackendValidation: false,
                ValidationUrl: null,
                ShowServerError: false,
                ErrorTargetField: null,
                ValidationParams: null,
                ErrorMessage: 'This field is required',
                ErrorMessageKey: 'error_field_required',
                RequiredOnlyWhen: null,
                Regex: null
              }
            ]
          },
          ResponseDataPath: null,
          OptionDisplayKeys: null,
          OptionValueKey: null,
          LoadImmediately: false,
          HierarchySelectionConfigs: null,
          DisabledDateConfig: null,
          RemoteUpdateConfig: null,
          RowNumber: 7,
          ColumnNumber: null,
          Id: 11118,
          ParentType: 'PART',
          ParentId: 57,
          FieldOptions: null,
          ValidationList: null,
          DefaultValue: null,
          Key: 'Label10',
          HasInfo: false,
          InfoText: null,
          Config: null,
          ColumnWidth: 3,
          RowWidth: 1,
          HasToggleButton: false
        }
      ],
      hierarchySelectionConfigs: [
        { Types: ['STATE'], Order: 1, Level: 2, OptionDisplayKeys: ['Name', 'Id', 'Code'], Placeholder: null, Label: 'Select STATE', Options: [], RemoteUrl: '/api/Hierarchy/GetHierarchiesByTypeParent' },
        { Types: ['DISTRICT'], Order: 2, Level: 3, OptionDisplayKeys: ['Name', 'Id', 'Code'], Placeholder: null, Label: 'Select DISTRICT', Options: [], RemoteUrl: '/api/Hierarchy/GetHierarchiesByTypeParent' },
        { Types: ['TU'], Order: 3, Level: 4, OptionDisplayKeys: ['Name', 'Id', 'Code'], Placeholder: null, Label: 'Select TU', Options: [], RemoteUrl: '/api/Hierarchy/GetHierarchiesByTypeParent' }
      ]
    }
  },
  computed: {
    ...mapState('Sidebar', ['sidebarDetails']),
    ...mapState({ test: (state) => state.sharedStore.test.test })
  },
  methods: {
    ...mapActions('Sidebar', ['getSidebarPermissions']),
    ...mapActions(['updateTest']),
    goToUP () {
      this.$router.push('/Dashboard/UnifiedPatient')
    },
    async getSidebar () {
      await this.getSidebarPermissions()
    },
    showModal1 () {
      this.isModal1Visible = true
    },
    closeModal1 () {
      this.isModal1Visible = false
    },
    showModal2 () {
      this.isModal2Visible = true
    },
    closeModal2 () {
      this.isModal2Visible = false
    },
    showModal3 () {
      this.isModal3Visible = true
    },
    closeModal3 () {
      this.isModal3Visible = false
    },
    changePage (number) {
      // todo: send this data to the table component.
      // console.log(number)
    },
    sortTable (sortData) {
      if (sortData !== undefined) {
        console.log(sortData)
      } else {
        console.log('No Table Sorting')
      }
    },
    redirectToPatientPage () {
      this.$router.push({ name: 'patient', params: { patientId: '314597' } })
    }
  },
  created () {
    EventBus.$on('event', this.action)
    EventBus.$on('sortTableEvent', this.sortTable)
  },
  action (stepNumber) {
    const redirectURL = this.Steps[stepNumber].url
    if (stepNumber <= this.CurrentStep) {
      console.log(redirectURL)
    }
  },
  async mounted () {
    await this.getSidebar()
    this.list = this.sidebarDetails
    this.updateTest(false)
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: revert;
  box-sizing: unset;
}
.sidebar-and-page-container {
  display: flex;
}
</style>
