<template>
  <v-treeview
    id="treeview"
    v-if="selectable"
    selectable
    :items="treeData"
    :open-on-click="true"
    :expand-icon="null"
    v-model="localValue"
    @input="onDataChange"
  ></v-treeview>
  <v-treeview
    id="treeview"
    v-else
    :items="treeData"
    :open-on-click="true"
  ></v-treeview>
</template>

<script>

export default {
  data () {
    return {
      localValue: []
    }
  },
  props: {
    treeData: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: true
    },
    value: {
      default: () => []
    }
  },
  mounted () {
    this.localValue = this.value
  },
  methods: {
    onDataChange () {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.mdi-minus-box {
    color: var(--primary-theme) !important
}

.mdi-checkbox-marked {
    color: var(--primary-theme) !important
}

.mdi-checkbox-blank-outline {
    color: $placeholderText !important;
}

#treeview .mdi-checkbox-blank-outline:disabled {
    color: $line !important;
}

.mdi-minus-box:hover, .mdi-checkbox-marked:hover, .mdi-checkbox-blank-outline:hover {
    color: $hover-border !important;
    filter: drop-shadow(0px 0px 6px rgba(66, 0, 255, 0.4));
}

.v-treeview-node__label {
  font-family: $labelFont;
  font-size: $labelSize !important;
  margin-left: 8px;
}

</style>
