<template>
  <div class="flex padding">
    <div class="text-ibm-plex-sans-14 ufmr-xl ufmt-md ufmb-md">{{ $t(label) }}
    </div>
    <div class="flex switch-ellipse"
    v-bind:class=getEllipseClass()
    v-on:click="toggle"
    >
      <div class="flex switch-circle"
      v-bind:class=getCircleClass()
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    name: {
      type: String,
      default: 'Toggle'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Label'
    }
  },
  methods: {
    toggle () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    },
    getEllipseClass () {
      return {
        'on-ellipse': this.value && !this.disabled,
        'off-ellipse': !this.value,
        'enabled-ellipse': !this.disabled,
        'disabled-on-ellipse': this.value && this.disabled
      }
    },
    getCircleClass () {
      return {
        'on-circle': this.value && !this.disabled,
        'off-circle': !this.value || this.disabled,
        'enabled-circle': !this.disabled
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.padding {
  padding: 10px;
}
.flex{
  display: flex;
  justify-content: left;
  flex: none
}

.text-ibm-plex-sans-14{
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
}
.switch-ellipse {
  height: 22px;
  width: 40px;
  align-items: center;
  border-radius: 100px;
  transition: all 0.5s;
  cursor:pointer;
}
.switch-circle {
  width: 22px;
  height: 22px;
  border-radius: 11px;
}
.on-ellipse {
  background: rgba(13, 193, 171, 0.15); // Can be changed with $ variables once decided on
  justify-content: flex-end;
}
.off-ellipse {
  background: rgba(160, 163, 189, 0.15);
  justify-content: flex-start;
}
.on-circle {
  background: $success;
}
.off-circle {
  background: $buttonDisabledColor;
}
.enabled-ellipse {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.disabled-on-ellipse {
  background: rgba(160, 163, 189, 0.15);
  justify-content: flex-end;
  cursor:default;
}
.enabled-circle {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.off-ellipse:not(.enabled-ellipse) {
  cursor:default;
}
</style>
