<template>
  <div>
    <div class="horizontalProgressBar" v-if="!isRadial">
      <div class ="loading-bar">
        <div class="percentage" :style="{'width' : percent + '%'}"></div>
      </div>
      <div class="text text-ibm-plex-sans" v-if="showPercent===true">
        {{ $t(percent) }}% Complete
      </div>
    </div>
    <div class="radialProgressBar" v-else>
        <div class="relative flex">
          <svg class="relative svg">
            <circle class="relative circle inner" ></circle>
            <circle class="relative circle outer"
              :style ="{'stroke-dashoffset': getOffset}"
            >
            </circle>
          </svg>
          <div class="flex number text-ibm-plex-sans">
             <h2> {{$t(percent) }}% </h2>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true
    },
    isRadial: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      showPercent: true
    }
  },
  computed: {
    percent () {
      return parseInt(this.progress).toFixed()
    },
    getOffset () {
      var progressValue = 440 - ((440 * this.percent) / 100)
      return (progressValue)
    }
  }
}
</script>

<style lang="scss" scoped>
  //Radial Progress Bar
  .flex{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .relative {
    position: relative;
    width: 150px;
    height: 150px;
  }
  .radialProgressBar {
    display: block;
    margin: auto;
    width: 150px;
    height: 150px;
    background: $bg2;
  }
  .svg {
    transform: rotate(-90deg);
  }
  .circle{
    cx: 70;
    cy: 70;
    r: 70;
    fill:none;
    stroke-width: 10;
    transform: translate(5px,5px);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
  }
  .inner {
    stroke-dashoffset: 0;
    stroke: $line;
  }
  .outer {
    stroke: $success;
    stroke-linecap: round;
  }
  .number {
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    color: $text;
  }
  // Horizontal Progress Bar
  body {
    background-color: $app-bg;
  }
  .horizontalProgressBar {
    display: block;
    margin: auto;
    width : 600px;
    padding: 10px;
    height : 45px;
  }
  .text {
    height :30px;
    display: flex;
    margin-top:6px;
    font-size: clamp(0.9rem, -0.375rem + 10vw, 1.25rem);
    font-weight: 500;
    color: $text;
  }
  .loading-bar {
    height: 15px;
    border-radius: 6rem;
    overflow: hidden;
    background-color: $line;
  }
  .percentage {
    height: 100%;
    background: linear-gradient(270deg, #0DC180 0.5%, #0DC1AB 100%);
    border-radius: 6rem;
  }
</style>
