<template>
    <div>
        <div class="parent">
          <select name="" id="" v-model="selectedToastType">
            <option v-for="item in opt" :key="item">{{item}}</option>
          </select>
          <input type="text" name="toastmsg" id="" v-model="toastMsg">
          <button @click="ToastWithX()">Generate Toast with X</button>
          <button @click="ToastWithbtn()">Generate Toast with btn</button>
        </div>
        <div class="parent">
            <div>
                <div class="primary"></div>
                <p>primary color</p>
            </div>
            <div>
                <div class="secondary"></div>
                <p>secondary color</p>
            </div>
            <div>
                <div class="text"></div>
                <p>text color</p>
            </div>
            <div>
                <div class="body"></div>
                <p>body color</p>
            </div>
            <div>
                <div class="label"></div>
                <p>label color</p>
            </div>
            <div>
                <div class="placeholderText"></div>
                <p>placeholderText color</p>
            </div>
            <div>
                <div class="line"></div>
                <p>line color</p>
            </div>
            <div>
                <div class="input-bg"></div>
                <p>input-bg color</p>
            </div>
            <div>
                <div class="bg"></div>
                <p>bg color</p>
            </div>
            <div>
                <div class="off-white"></div>
                <p>off-white color</p>
            </div>
            <div>
                <div class="white"></div>
                <p>white color</p>
            </div>
            <div>
                <div class="success"></div>
                <p>success color</p>
            </div>
            <div>
                <div class="info"></div>
                <p>info color</p>
            </div>
            <div>
                <div class="warning"></div>
                <p>warning color</p>
            </div>
            <div>
                <div class="error"></div>
                <p>error color</p>
            </div>
            <div>
                <div class="black"></div>
                <p>black color</p>
            </div>
        </div>
    </div>
</template>
<script>
import { ToastType, defaultToast, toastWithActionButton } from '../../../utils/toastUtils.js'
export default {
  name: 'ThemeExmaple',
  data () {
    return {
      toastMsg: 'Sample msg',
      opt: ToastType,
      selectedToastType: ToastType.Neutal
    }
  },
  methods: {
    ToastWithX () {
      defaultToast(this.selectedToastType, this.toastMsg, null)
    },
    ToastWithbtn () {
      toastWithActionButton(this.selectedToastType, this.toastMsg, 'test', () => alert('tesing 123'), null)
    }
  }
}
</script>

<style lang="scss" scoped>
p{
  color: $text;
  font-weight: bold;
}
.parent{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem;
  flex-wrap: wrap;
  background-color: $par-bg-col;
}
.primary{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: var(--primary-theme);
}
.secondary{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $secondary;
}

.text{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $text;
}

.body{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $body;
}

.placeholderText{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $placeholderText;
}
.line{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $line;
}
.input-bg{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $input-bg;
}
.bg{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $bg;
}
.off-white{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $off-white;
}
.white{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $white;
}
.success{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $success;
}
.info{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $info;
}
.warning{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $warning;
}
.error{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $error;
}
.black{
  height: 100px;
  width: 100px;
  margin: 1rem;
  border: 2px dashed $black;
  background-color: $black;
}

</style>
