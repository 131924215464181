<template>
  <div v-if="ShowChip" class="container">
    <i :class="['chips', 'text-ibm-plex-sans', color, getChipType()]">
      {{ $t(label) }}
      <button v-if="showButton" class="button" @click="showChip">
        <ChipsCancel :color="color" />
      </button>
    </i>
  </div>
</template>

<script>
import ChipsCancel from './svg-icons/chipsCancel.vue'
export default {
  data () {
    return {
      ShowChip: true
    }
  },
  name: 'Chips',
  props: {
    label: {
      type: String,
      default: 'Enabled'
    },
    color: {
      type: String,
      default: 'primary'
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ChipsCancel
  },
  methods: {
    /* eslint-disable */
    showChip() {
      this.ShowChip = false;
    },
    getChipType() {
      if (this.showButton) {
        return "withicon";
      } else {
        return "withouticon";
      }
    },
    /* eslint-enable */
  }
}
</script>

<style lang= "scss" scoped>
.container {
  display: inline-block;
}
.button {
  border: none;
  background: none;
  display: flex;
  position: relative;
  right: -3.5px;
}
.chips {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  padding: 0px;
  border-width: 1px;
  border-radius: 16px;
}
.svg:hover {
  background-color: $primaryLight;
}

.white.withouticon {
  width: 77px;
  height: 34px;
  color: $white;
  background: none;
  border-style: solid;
  border-color: $white;
}
.white.withicon {
  width: 100px;
  height: 34px;
  color: $white;
  background: none;
  border-style: solid;
  border-color: $white;
}
.black.withouticon {
  width: 77px;
  height: 34px;
  color: $text;
  background-color: $white;
  border: none;
}
.black.withicon {
  width: 100px;
  height: 34px;
  color: $text;
  background-color: $white;
  border: none;
}
.primary.withouticon {
  width: 77px;
  height: 34px;
  color: var(--primary-theme);
  background: none;
  border-style: solid;
  border-color: var(--primary-theme);
}
.primary.withicon {
  width: 100px;
  height: 34px;
  color: var(--primary-theme);
  background: none;
  border-style: solid;
  border-color: var(--primary-theme);
}
.grey.withouticon {
  width: 77px;
  height: 34px;
  color: $buttonDisabledColor;
  background-color: $line;
  border: none;
}
.grey.withicon {
  width: 100px;
  height: 34px;
  color: $buttonDisabledColor;
  background-color: $line;
  border: none;
}
</style>
