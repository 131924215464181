<template>
<div class="ufmt-xs ufmb-xs"  @click="updateAccordianStatus()">
    <div :class="status === expandedState ? 'customAccordionContainerExpanded':'customAccordionContainer'">
      <div class="customAccordion-TitleBar grid">
        <div>
        {{ $t(titleText) }}
        </div>
        <div></div>
        <div class="flex expand-collapse-button">
          <div v-if="status === collapsedState" class="expand" ></div>
          <div v-else class="collapse ufmt-sm"></div>
        </div>
      </div>
      <div></div>
        <div v-if="status === expandedState && contentIsHtml === true" class="accordianBody" v-html="content"></div>
        <div v-if="status === expandedState && contentIsHtml === false" class="accordianBody">{{ $t(content) }}</div>
    </div>
</div>
</template>

<script>
import { accordionState } from '../../../utils/accordionUtils.js'
export default {
  props: {
    AccordionStatus: {
      type: accordionState,
      default: accordionState.Collapsed,
      required: false
    },
    // for now not using isDisabled
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    titleText: {
      type: String,
      required: true
    },
    contentIsHtml: {
      type: Boolean,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      status: this.$props.AccordionStatus,
      collapsedState: accordionState.Collapsed,
      expandedState: accordionState.Expanded
    }
  },
  methods: {
    updateAccordianStatus () {
      switch (this.status) {
        case accordionState.Expanded :
          this.status = accordionState.Collapsed
          break
        case accordionState.Collapsed :
        default:
          this.status = accordionState.Expanded
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid{
  display: grid;
}
.flex{
  display: flex;
}
.customAccordionContainer{
  padding: 1rem;
  border: 1px solid $bg;
  border-radius: 8px;
  background-color: $bg;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
.customAccordionContainerExpanded{
  padding: 1rem;
  border: 1px solid $bg;
  border-radius: 8px;
  background-color: $bg;
}
.customAccordionContainerExpanded .customAccordion-TitleBar{
  padding-bottom: 1rem;
}
.customAccordion-TitleBar{
  grid-template-columns: 50% 40% 10%;
  padding: 0 0.5rem;
}
.accordianBody{
  padding: 0 0.5rem;
}
.customAccordionContainer:hover{
  border: 1px solid var(--primary-theme);
}

.expand-collapse-button{
justify-content: flex-end;
}
.expand{
  width: 1.25rem;
  height: 1.25rem;
  background: url('~@/assets/accordianExpand.svg');
  background-repeat:no-repeat;
}
.expand:hover{
  cursor:pointer;
}
.collapse{
  width: 1.25rem;
  height: 1.25rem;
  background: url('~@/assets/accordianCollapse.svg');
  background-repeat:no-repeat;
}
.collapse:hover{
  cursor:pointer;
}
</style>
