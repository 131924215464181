<template>
    <div class="regularStepperContainer" v-if="!isRadial"> <!--Horizonal and Vertical Stepper-->
        <ul  :class="getStepperClass()">
          <li @click="onStepClick(index)"
              :class="getStepClass(index)" v-for="(step,index) in steps" :key="index"
              :style="{'width': 100/totalSteps + '%'}"
          >
          <div :class="getTitleClass()">
            {{step.title}}
          </div>
          </li>
        </ul>
    </div>
  <div class="radialStepperContainer" v-else> <!--Radial Stepper -->
    <div class="relative flex">
      <svg class="relative svg">
        <circle class="relative circle inner" ></circle>
        <circle class="relative circle outer"
          :style ="{'stroke-dashoffset': getOffset}"
        >
        </circle>
      </svg>
      <div class="flex number">
        <h2> {{currentStep}} of {{totalSteps}}</h2>
      </div>
    </div>
  <div class= "primaryText">
    {{primaryText}}
  </div>
  <div class= "secondaryText">
    {{secondaryText}}
  </div>
  </div>
</template>
<script>
import EventBus from '@/app/shared/event-bus.js'
export default {
  emits: ['event'],
  props: ['steps', 'currentStep', 'isRadial', 'Orientation', 'totalSteps', 'primaryText', 'secondaryText'],
  methods: {
    /* eslint-disable */
    getStepClass (index) {
      if(index < this.currentStep)
        return('active');
      else if(index == this.currentStep)
        return('currStep');
      else
        return('');
    },
    onStepClick (index) {
      EventBus.$emit('event', index)
    },
    getTitleClass () {
      if (this.Orientation == 'h') { return ('titleH') } else { return ('titleV') }  
    },
    getStepperClass () {
      if (this.Orientation == 'h') { return ('progressbarH') } else { return ('progressbarV') }
    }
    /* eslint-enable */
  },
  computed: {
    getProgress () {
      if (this.currentStep > this.totalSteps) { return (100) }
      var progress = (this.currentStep / this.totalSteps) * 100
      return (progress)
    },
    getOffset () {
      var progressValue = 440 - ((440 * this.getProgress) / 100)
      return (progressValue)
    }
  }
}
</script>
<style lang="scss" scoped>
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative{
  position: relative;
  width: 150px;
  height: 150px;
}
.regularStepperContainer{
  position: relative;
  z-index: 0;
}
.titleV
{
  position: relative;
  top:-75px;
  transform: rotate(270deg);
}
.titleH
{

}
.progressbarH{
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressbarV{
  position: relative;
  top:20vw;
  max-width: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateZ(90deg);
}
.progressbarH li, .progressbarV li{
  position: relative;
  text-align: center;
  list-style-type: none;
  color:$label;
  font-family: IBM Plex Sans,sans-serif;
}
.progressbarH li:before{
  content:'';
  display: block;
  width: 18px;
  height: 18px;
  border: 3px solid $line;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background: $line;
}
.progressbarV li:before{
  content:'';
  display: block;
  width: 18px;
  height: 18px;
  border: 3px solid $line;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background:$line;
  transform: rotate(-90deg);
}
.progressbarH li:after, .progressbarV li:after{
  content:'';
  position: absolute;
  width:100%;
  height: 3px;
  background: $line;
  top: 10.5px;
  left: -50%;
  z-index: -1;
}
.progressbarH li.currStep, .progressbarV li.currStep{
  color:$success;
}
.progressbarH li.currStep::before, .progressbarV li.currStep::before{
  content: '';
  border: 3px solid $success;
  box-shadow: 0px 2px 2px rgba(30, 35, 76, 0.2);
  background: $white;
}
.progressbarH li.active,.progressbarV li.active {
  color: $text;
}
.progressbarH li.active:before, .progressbarH li.active-li:before, .progressbarV li.active:before, .progressbarV li.active-li:before{
  display: flex;
  align-items: center;
  justify-content: center;
  content:'\2713';
  border-color: $success;
  background: $success;
  color: $white
}
.progressbarH li.active:after, .progressbarH li.active-li:after,.progressbarH li.currStep::after,.progressbarV li.active:after, .progressbarV li.active-li:after,.progressbarV li.currStep::after {
 background: $success;
}
.progressbarH li:first-child:after, .progressbarV li:first-child:after{
 content: none;
}
.radialStepperContainer{
  position: relative;
  width: 400px;
  height: 150px;
  background: $bg2;
}
.svg{
  transform: rotate(-90deg);
}
circle{
  cx: 70;
  cy: 70;
  r: 70;
  fill:none;
  stroke-width: 10;
  transform: translate(5px,5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}
.inner{
  stroke-dashoffset: 0;
  stroke: $line;
}
.outer{
  stroke: $success;
}
.number{
  position: absolute;
  font-size: 20px;
  color: $text;
}
.primaryText{
  position: absolute;
  color: $text;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 32px;
  top:40px;
  right:0px;
}
.secondaryText{
  position: absolute;
  bottom:40px;
  right:15px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 26px;
  color: $label;
}
</style>
